import React, { useState } from 'react'
import styles from './ProjectsHeader.module.css'
import searchNormal from '../assets/search-normal.svg'
import arrowCircleDown from '../assets/arrow-circle-down.svg'

const sortBy = [
    {
        id: 1,
        sort: "Newest"
    },
    {
        id: 2,
        sort: "Oldest"
    }
]
const ProjectsHeader = () => {
    const [sort, setSort] = useState("Newest")

    const [isSort, setIsSort] = useState(false)

    const handleSort = (sort) => {
        setSort(sort)
        setIsSort(false)
    }
    return (
        <>
            <section className={styles.wrapper}>
                <div className={styles.projects}>
                    <h4 className={styles.projectsHeading}>Find the project you want</h4>
                    <div>
                        <div className={styles.searchBarContainer}>
                            <div className={styles.searchBar}>
                                <img src={searchNormal} alt="search icon" />
                                <input className={styles.searchBarInput} type="text" name="" id="" placeholder='Search something...' />
                            </div>
                        </div>
                        <div className={isSort ? `${styles.sortBy} ${styles.active}` : styles.sortBy}>
                            <span className={styles.sortByCaption}>Sort by:</span>
                            <div className={styles.sortBySelect}>
                                <span className={styles.sortByOptions}>{sort}</span>
                                <img src={arrowCircleDown} onClick={() => setIsSort(!isSort)} alt="circled shape down arrow icon" />
                            </div>
                            <div className={styles.sort_wrapper}>
                                {
                                    sortBy.map((sort) => (
                                        <li key={sort.id} onClick={() => handleSort(sort.sort)}>{sort.sort}</li>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectsHeader