import React from "react";
import styles from "./style.module.css";
import Badge from "../Badge";

const badges = [
  {
    icon: "/shield-cross.svg",
    text: "KYC",
    bgColor: "#E9F2FF33",
  },
  {
    icon: "/shield-tick.svg",
    text: "AUDIT",
    bgColor: "#E9F2FF33",
  },
  {
    icon: "/tick-circle.svg",
    text: "Finished",
    bgColor: "#0C9564",
  },
];

const ProjectInfo = ({ ido }) => {
  return (
    <section className={styles.container}>
      <img className={styles.cardBg} src="/card-bg.png" alt="card-bg" />

      <div className={styles.header}>
        <img
          className={styles.avatar}
          src="/launcher-avatar.svg"
          alt="avatar"
        />

        <div className={styles.wraper}>
          <div>
            <h3 className={styles.heading}>{ido.token_name}</h3>
            <div className={styles.spacing}>
              <span className={styles.textLight}>{ido.ido_type}</span>

              <div className={styles.icon}>
                <img src="/youtube.svg" alt="" />
              </div>
              <div className={styles.icon}>
                <img src="/global.svg" alt="" />
              </div>
            </div>
          </div>

          <div className={styles.badges}>
            {badges.map((badge) => (
              <Badge key={badge.text} {...badge} />
            ))}
          </div>
        </div>
      </div>

      <p className={styles.textFaded}>
        {ido.project_description}
      </p>

      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Token Name</p>
        {/* right text */}
        <p className={styles.textLight}>{ido.token_name}</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Token Symbol</p>
        {/* right text */}
        <p className={styles.textLight}>{ido.token_symbol}</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Token Supply</p>
        {/* right text */}
        <p className={styles.textLight}> {ido.token_supply} {ido.token_symbol}</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Token For Presale</p>
        {/* right text */}
        <p className={styles.textLight}>{ido.tokens_for_sale} {ido.token_symbol}</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Token For Liquidity</p>
        {/* right text */}
        <p className={styles.textLight}>{ido.tokens_for_liquidity} {ido.token_symbol}</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Liquidity Precent</p>
        {/* right text */}
        <p className={styles.textLight}>{ido.percentage_for_liquidity}%</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Softcap</p>
        {/* right text */}
        <p className={styles.textLight}>7.2</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Hardcap</p>
        {/* right text */}
        <p className={styles.textLight}>12.5</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Rate</p>
        {/* right text */}
        <p className={styles.textLight}>14</p>
      </div>
      <hr className={styles.divider} />
      <div className={styles.textAround}>
        {/* left text */}
        <p className={styles.textDark}>Listing On</p>
        {/* right text */}
        <p className={styles.textSpecial}>Uniswap</p>
      </div>
    </section>
  );
};

export default ProjectInfo;
