import React from 'react'
import styles from './Features.module.css'

const FeaturesCards = ({ bgColor, icon, title, description }) => {
    return (
        <>
            <div className={bgColor === "dark" ? `${styles.featuresCards} ${styles.active}` : styles.featuresCards}>
                <div className={styles.iconImageContainer}>
                    <img src={icon} alt="" />
                </div>
                <h6 className={styles.cardsHeading}>{title}</h6>
                <p className={styles.cardsPara}>{description}</p>
            </div>
        </>
    )
}

export default FeaturesCards