import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

import KeyValueInput from "./KeyValueInput/KeyValueInput";
import styles from "./EnvironmentVariables.module.css";

const EnvironmentVariables = ({ wallets_amount_parser }) => {
  const [values, setValues] = useState({});
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    let addresses = [];
    let amounts = [];

    //   {
    //     "key1": "0x1",
    //     "value1": "5000",
    //     "key2": "0x2",
    //     "value2": "10000"
    // }
    //extract key1, key2, key3, etc to addresses
    //extract value1, value2, value3, etc to amounts
    for (let i = 1; i <= Object.keys(values).length / 2; i++) {
      addresses.push(values[`key${i}`]);
      amounts.push(values[`value${i}`]);
    }

    wallets_amount_parser(addresses, amounts);
  }, [inputs, values]);
  const createNewKey = () => {
    const newInput = {
      key: {
        type: "text",
        name: `key${inputs.length + 1}`,
        placeholder: "Wallet Address",
      },
      value: {
        type: "text",
        name: `value${inputs.length + 1}`,
        placeholder: "Amount",
      },
    };

    setInputs((prevInputs) => [...prevInputs, newInput]);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const onDeleteRow = (name) => {
    setInputs((prevInputs) =>
      prevInputs.filter((input) => input.key.name !== name)
    );
  };
  const submitHandler = (e) => {
    e.preventDefault();
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleAndTagline}>
        <p className={styles.title}>Transfer Tokenomics:</p>
        <p className={styles.tagline}>
          Send token to multiple wallets
        </p>
      </div>
      <form action="" className={styles.form} onSubmit={submitHandler}>
        <div className={styles.keyValueContainer}>
          <div className={[styles.labelContainer].join(" ")}>
            {inputs.length > 0 && <>
              <p className={styles.label}>Wallet Address</p>
              <p className={styles.label}>Amount</p>
            </>}
            <div className={styles.box}></div>
          </div>


          {inputs.map((input, i) => (
            <KeyValueInput
              key={i}
              id={i}
              input={input}
              keyValue={{
                key: values[input.key.name],
                value: values[input.value.name],
              }}
              onChange={onChange}
              onDelete={() => onDeleteRow(input.key.name)}
            />
          ))}
        </div>{" "}
        <button className={styles.createNewKey} onClick={createNewKey}>
          {" "}
          <AiOutlinePlus className={styles.plusIcon} /> Add new Wallet Address
        </button>
      </form>
    </div>
  );
};

export default EnvironmentVariables;
