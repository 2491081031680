import React, { useState, useEffect } from 'react'
import styles from './Navbar.module.css'
import binomo from '../../images/binomo.svg'
import logo from '../../images/Logo.svg'
import { NavLink } from 'react-router-dom'
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import { ConnectButton } from '@rainbow-me/rainbowkit';

const navItems = [
    { navItem: "Home", to: "/" },
    // { navItem: "Stacking", to: "/page2" },
    { navItem: "Mint Token", to: "/minter" },
    { navItem: "FAQ", to: "/faq" },
    { navItem: "Docs", to: "/faq" },
];

const Navbar = () => {
    const [sidebar, setSidebar] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition >= 60) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up event listener
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <section className={styles.wrapper}>
            <div className={isScrolled ? `${styles.container} ${styles.active}` : styles.container}>
                <div>
                    <div className={styles.nav_left}>
                        <img src={logo} alt="" />
                    </div>
                    <RxHamburgerMenu className={styles.hamburger} onClick={() => setSidebar(true)} />
                    <div className={sidebar ? `${styles.nav_right} ${styles.active}` : styles.nav_right}>
                        <MdOutlineClose onClick={() => setSidebar(false)} className={styles.close} />
                        <div className={`${styles.nav_links} nav_links`} id='nav_links'>
                            {
                                navItems.map((el) => (
                                    <NavLink key={el.navItem} onClick={() => setSidebar(false)} to={el.to}>{el.navItem}</NavLink>
                                ))
                            }
                        </div>
                        <div className={styles.buttons}>
                            {/*  */}
                            <ConnectButton />;
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Navbar