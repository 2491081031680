import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'
import footer_background from '../../images/footer_background.png'
import Logo from '../../images/Logo.svg'
import facebook from '../../images/facebook.svg';
import slack from '../../images/slack.svg';
import youtube from '../../images/youtube.svg';
import zoom from '../../images/zoom.svg';
import whatsapp from '../../images/whatsapp.svg';
import sms from '../../images/sms.svg'


const Footer = () => {
    return (
        <>

            <section className={styles.footer_wrapper}>
                <div className={styles.footer_top}>
                    <div className={styles.footer_desc}>
                        <img src={Logo} alt="logo" />
                        <p>Discover and invest in the best blockchain projects with the guidance of AI-driven insights and our team of experts.</p>
                        <div className={styles.icons_list}>
                            <div className={styles.icons}>
                                <img src={facebook} alt="facebook" />
                            </div>
                            <div className={styles.icons}>
                                <img src={slack} alt="slack" />
                            </div>
                            <div className={styles.icons}>
                                <img src={youtube} alt="youtube" />
                            </div>
                            <div className={styles.icons}>
                                <img src={zoom} alt="zoom" />
                            </div>
                            <div className={styles.icons}>
                                <img src={whatsapp} alt="whatsapp" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.link_flex}>
                        <div className={styles.links}>
                            <h5>Company</h5>
                            <Link to="/">About</Link>
                            <Link to="/">Careers</Link>
                            <Link to="/">Affiliates</Link>
                            <Link to="/">Press</Link>
                            <Link to="/">Investors</Link>
                        </div>
                        <div className={styles.links}>
                            <h5>Company</h5>
                            <Link to="/">About</Link>
                            <Link to="/">Careers</Link>
                            <Link to="/">Affiliates</Link>
                            <Link to="/">Press</Link>
                            <Link to="/">Investors</Link>
                        </div>
                        <div className={styles.links}>
                            <h5>Company</h5>
                            <Link to="/">About</Link>
                            <Link to="/">Careers</Link>
                            <Link to="/">Affiliates</Link>
                            <Link to="/">Press</Link>
                            <Link to="/">Investors</Link>
                        </div>
                    </div>
                    <div className={styles.newsLetter}>
                        <h4>Subscribe our newsletter</h4>
                        <div>
                            <div className={styles.input}>
                                <img src={sms} alt="" />
                                <span className={styles.vertical_line}></span>
                                <input type="email" name="email" id="email" placeholder='Enter your email address' />
                            </div>
                            <button className={styles.newsletter_btn}>Send</button>
                        </div>
                        <p>
                            {`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam...`}
                        </p>
                    </div>
                </div>
                <span className={styles.horizontal_line}></span>
                <div className={styles.footer_bottom}>
                    <div className={styles.footer_bottom_left}>
                        <span className={styles.round_rectangle}></span>
                        <p>{`Copyright 2023 © Pixpars`}</p>
                    </div>
                    <div className={styles.footer_bottom_right}>
                        <p>Terms</p>
                        <span className={styles.round_rectangle}></span>
                        <p>Privacy</p>
                        <span className={styles.round_rectangle}></span>
                        <p>Cookies</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer