import React from 'react'
import styles from './Features.module.css'
import FeaturesCards from './FeaturesCards'
import vaultActive from '../../images/vault_active.svg'
import vault from '../../images/vault.svg'
import safe from '../../images/safe.svg'


const Features = () => {
    return (
        <>
            <section className={styles.wrapper}>
                <div className={styles.features}>
                    <div className={styles.featureHeading}>OUR FEATURES</div>
                    <div className={styles.featureHeadingInfo}>Why choose us?</div>
                    <p className={styles.featurePara}>At ${process.env.REACT_APP_NAME}, we seamlessly blend AI-powered analysis with human expertise to present meticulously vetted blockchain opportunities. Designed for the discerning investor, our platform emphasizes security, education, and community engagement. Backed by an experienced team and adhering to global regulations, we're your trusted partner in navigating the crypto investment landscape.</p>
                    <div className={styles.featuresCardsContainer}>
                        <FeaturesCards icon={vault} title={"Vetted Opportunities"} description={"Each project we feature undergoes a rigorous vetting process, ensuring it aligns with our standards of innovation, transparency, and viability."}/>
                        <FeaturesCards bgColor={"dark"} icon={vaultActive} title={"Expert Team"} description={"Our team comprises industry veterans with deep domain knowledge. We understand the intricacies of the crypto world and are committed to delivering unmatched value."} />
                        <FeaturesCards icon={safe} title={"Community Driven"} description={"We pride ourselves on a robust and engaged community, fostering collaboration, discussions, and shared success among our members."} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features