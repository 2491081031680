import styles from "./OurPartners.module.css";
export const LastSection = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.text}>
          <h5>OUR PARTNERS</h5>
          <h2>We have partnered with Top<span className={styles.web3}>WEB3 Projects</span></h2>
          <p>Creating opportunities for our holders</p>
        </div>
        <div className={styles.companies}>
          <div className={styles.logo}>
            <img className={styles.groupIcon} alt="" src="/assets/group.svg" />
          </div>
          <div className={`${styles.logo} ${styles.active}`}>
            <img
              className={styles.groupIcon1}
              alt=""
              src="/assets/group1@2x.png"
            />
          </div>
          <div className={styles.logo}>
            <img className={styles.groupIcon2} alt="" src="/assets/group2.svg" />
          </div>
          <div className={styles.logo}>
            <img className={styles.groupIcon3} alt="" src="/assets/group3.svg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LastSection;
