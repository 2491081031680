import React from "react";
import styles from "./style.module.css";
import { Pie, PieChart, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Presale", value: 300 },
  { name: "Locked", value: 400 },
  { name: "Liquidity", value: 400 },
  { name: "Burnt", value: 200 },
  { name: "Unlocked", value: 100 },
  { name: "Fress", value: 900 },
];

const colors = [
  "#5AC7E6",
  "#7964CD",
  "#50546F",
  "#D33636",
  "#63CD82",
  "#B225BF",
];

const TokenMatrics = () => {
  return (
    <div className={styles.card}>
      <h6 className={styles.heading}>Token Matrics</h6>

      <div className={styles.subcard}>
        <div>
          <p className={styles.textSecondary}>Total Token Metrics</p>
          <p className={styles.textPrimary}>100B</p>
        </div>
        <div>
          <p className={styles.textSecondary}>Diluted Market Cap</p>
          <p className={styles.textPrimary}>$185,291</p>
        </div>
      </div>

      {/* chart */}
      <div className={styles.chart}>
        <ResponsiveContainer
          // aspect={4 / 3}
          className={styles.responsive}
        >
          <PieChart onMouseEnter={() => { }}>
            <Pie
              data={data}
              // cx={120}
              // cy={200}
              innerRadius={80}
              outerRadius={110}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              cornerRadius={3}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  strokeOpacity={0}
                  fill={colors[index]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

      </div>
      <div className={styles.labels}>
        {data.map(({ name }, i) => (
          <div className={styles.labelWithDot}>
            <div
              className={styles.labelColor}
              style={{ background: colors[i] }}
            ></div>
            <p>{name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenMatrics;
