import React from 'react'
import styles from './LaunchPad.module.css';
import arrowCurve from '../../images/arrow_curve.svg';
import arrow_right from '../../images/arrow-right.svg';

export const Banner = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content_left}>
            <h4>Ready to launch?</h4>
            <p>{`Are you ready to propel your blockchain initiative to new heights? ${process.env.REACT_APP_NAME} is here to partner with groundbreaking projects like yours!`}</p>
            <img src={arrowCurve} alt="" />
          </div>
          <div className={styles.content_right}>
            <button className={styles.button}>
              <p>
                {`Get Started Now`}
              </p>
              <div className={styles.arrow_right}>
                <img src={arrow_right} alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}


export default Banner;