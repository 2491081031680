import React from 'react'
import styles from './ProjectsCards.module.css'
import Colon from '../../assets/Colon.svg'
import progressBar from '../../images/progressBar.svg'
import closeSquare from "../../assets/close-square.svg"
import programmingArrows from "../../assets/programming-arrows.svg"


const Cards = ({ project }) => {

    const {
        header_background,
        groupImage,
        status,
        heading,
        progress,
        btn_color,
        progress_color
    } = project;

    const progress_remains = 100 - progress;
    return (
        <div className={styles.cardsContainer}>
            <img src={header_background} alt='' />
            <div className={styles.groupAndStatus}>
                <img src={groupImage} alt='' className={styles.groupImage} />
                {status === "Failed" ? <>
                    <div className={`${styles.cardStatus} ${styles.cardStatusError}`}>
                        <img src={closeSquare} alt="" />
                        <span className={styles.status}>{status}</span>
                    </div>
                </> : <>
                    <div className={`${styles.cardStatus} ${styles.cardStatusSuccess}`}>
                        <img src={programmingArrows} alt="" />
                        <span className={styles.status}>{status}</span>
                    </div>
                </>
                }
            </div>
            <h5 className={styles.cardHeading}>{heading}</h5>
            <div className={styles.dayAndTimeContainer}>
                {/*  */}
                <button className={styles.dayAndTimeChip}>
                    <span className={styles.dayAndTimeChipText}>03</span>
                </button>
                <img src={Colon} alt="" />
                {/*  */}
                <button className={styles.dayAndTimeChip}>
                    <span className={styles.dayAndTimeChipText}>01</span>
                </button>
                <img src={Colon} alt="" />
                {/*  */}
                <button className={styles.dayAndTimeChip}>
                    <span className={styles.dayAndTimeChipText}>32</span>
                </button>
                <img src={Colon} alt="" />
                {/*  */}
                <button className={styles.dayAndTimeChip}>
                    <span className={styles.dayAndTimeChipText}>12</span>
                </button>
            </div>

            <div className={styles.cardInfo}>
                {/*  */}
                <div className={styles.cardInfoParameters}>
                    <span className={styles.cardInfoParametersKey}>Hardcap:</span>
                    <span className={styles.cardInfoParametersValue}>10000.0</span>
                </div>
                {/*  */}
                <div className={styles.cardInfoParameters}>
                    <span className={styles.cardInfoParametersKey}>Softcap:</span>
                    <span className={styles.cardInfoParametersValue}>0.5</span>
                </div>
                {/*  */}
                <div className={styles.cardInfoParameters}>
                    <span className={styles.cardInfoParametersKey}>Lquidity:</span>
                    <span className={styles.cardInfoParametersValue}>12%</span>
                </div>
                {/*  */}
                <div className={`${styles.cardInfoParameters} ${styles.cardInfoParametersNoBorder}`}>
                    <span className={styles.cardInfoParametersKey}>WL:</span>
                    <span className={styles.cardInfoParametersValue}>120 SPOTS</span>
                </div>
            </div>

            <div className={styles.progressBarConatainer}>
                <span className={styles.progressBarText}>Progress <span className={styles.progressBarPercentageText}>({progress}%)</span></span>
                <div>
                    <div className={styles.progressBar}>
                        <img src={progressBar} className={`progress_${progress_color}`} style={{ left: `-${progress_remains}%` }} alt="" />
                    </div>
                </div>
                <div className={styles.USDT}>
                    <span>136.12 USDT</span>
                    <span>10000.0 USDT</span>
                </div>
            </div>

            {btn_color === 'gray' ? <button
                onClick={
                    () => {
                        window.location.href = `/ido/${project.contract_address}`
                    }
                }
                className={`${styles.button} ${styles.inActiveButton}`}>View Project</button> : <button className={`${styles.button} ${styles.activeButton}`}>See Project</button>}
        </div>
    )
}

export default Cards