import React from 'react'
import Cards from './Cards'
import styles from './ProjectsCards.module.css'
import Subract1 from "../../assets/Subtract-1.png"
import Subract2 from "../../assets/Subtract-2.png"
import Subract3 from "../../assets/Subtract-3.png"
import Group1 from "../../assets/Group_1.svg";
import Group2 from "../../assets/Group_2.svg";
import Group3 from "../../assets/Group_3.svg";

const projects = [
  {
    id: 1,
    progress: 90,
    header_background: Subract1,
    groupImage: Group1,
    status: "Closed",
    heading: "Super Launchers",
    btn_color: "gray",
    progress_color: "red",
    contract_address: "0x"
  },
  // {
  //   id: 2,
  //   progress: 26,
  //   header_background: Subract2,
  //   groupImage: Group2,
  //   status: "Processing",
  //   heading: "New Bitcoin",
  //   btn_color: "gray",
  //   progress_color: "yellow"
  // },
  // {
  //   id: 1,
  //   progress: 75,
  //   header_background: Subract3,
  //   groupImage: Group3,
  //   status: "Processing",
  //   heading: "Super Launchers",
  //   btn_color: "blue",
  //   progress_color: "blue"
  // },
]

const ProjectsCards = ({ projects }) => {
  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.projectsCards}>
          {
            projects.map((project) => (
              <Cards project={project} key={project.id} />
            ))
          }
        </div>
      </section>
    </>
  )
}

export default ProjectsCards