import React from "react";
import styles from "./style.module.css";
const LearnMore = ({ ido }) => {
  return (
    <div className={styles.learnMore}>
      <img className={styles.arrow} src="/illustation-arrow.svg" alt="" />
      <div>
        <p className={styles.textSecondary}>Learn More About</p>
        <p className={styles.textPrimary}>{ido.token_name}</p>
      </div>

      <button className={styles.specialButton}>
        <span>see more</span>

        <div className={styles.arrowBox}>
          <img src="/arrow-right.svg" alt="" />
        </div>
      </button>
    </div>
  );
};

export default LearnMore;
