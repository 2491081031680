import React from "react";
import styles from "./style.module.css";

const Badge = ({ icon, bgColor, text }) => {
  return (
    <div className={styles.badge} style={{ backgroundColor: bgColor }}>
      <img className={styles.icon} src={icon} alt="" />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Badge;
