import "./App.css";
import Home from "./pages/Home";
import { Route, Routes } from "react-router";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import IdoPage from "./pages/NewPage";
import OurPartners from "./components/OurPartners/OurPartners";
import LaunchPad from "./components/LaunchPad/LaunchPad";
import CreateToken from "./pages/CreateToken/CreateToken";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Subract1 from "./assets/Subtract-1.png";
import Group1 from "./assets/Group_1.svg";
function App() {
  const Arb = {
    id: 42161,
    name: "Arbitrum Mainnet",
    network: "hepton",
    iconUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
    iconBackground: "#fff",
    nativeCurrency: {
      decimals: 18,
      name: "Arbitrum",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: ["https://rpc.ankr.com/arbitrum"],
      },
    },
    blockExplorers: {
      default: { name: "Arbiscan", url: "https://arbiscan.io" },
    },
    testnet: false,
  };

  const { chains, publicClient } = configureChains(
    [arbitrum, bsc],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "My RainbowKit App",
    projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd",
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  const projects = [
    {
      id: 1,
      progress: 90,
      header_background: Subract1,
      groupImage: Group1,
      status: "Upcoming",
      heading: "Buffalo Token",
      token_name: "Buffalo Token",
      token_symbol: "VARMIN",
      ido_network: "Arbitrum",
      ido_type: "Fair Launch",
      token_supply: "100,000,000",
      tokens_for_sale: "10,000,000",
      tokens_for_liquidity: "10,000,000",
      percentage_for_liquidity: "10",
      purchase_currency: "USDT",
      purchase_currency_contract_address:
        "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      btn_color: "gray",
      progress_color: "red",
      contract_address: "0x91D63779dAB924395E918Bf16C5CB466d04b40f0",

      project_description:
        "Varma token is a token developed as utility token for cows in india, Varma token has raised 300m$ in private sale. mostly participated by Cows in india and buffalos",
    },
    // {
    //   id: 2,
    //   progress: 26,
    //   header_background: Subract2,
    //   groupImage: Group2,
    //   status: "Processing",
    //   heading: "New Bitcoin",
    //   btn_color: "gray",
    //   progress_color: "yellow"
    // },
    // {
    //   id: 1,
    //   progress: 75,
    //   header_background: Subract3,
    //   groupImage: Group3,
    //   status: "Processing",
    //   heading: "Super Launchers",
    //   btn_color: "blue",
    //   progress_color: "blue"
    // },
  ];
  return (
    <>
      <div className="app">
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home projects={projects} />} />
              <Route
                path="/ido/:ido"
                element={<IdoPage projects={projects} />}
              />
              <Route path="/minter" element={<CreateToken />} />
            </Routes>

            <LaunchPad />
            <OurPartners />
            <Footer />
          </RainbowKitProvider>
        </WagmiConfig>
      </div>
    </>
  );
}

export default App;
