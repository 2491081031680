import React from 'react'
import HeroSection from '../components/herosection/heroSection';
import styles from '../styles/home.module.css';
import ProjectsHeader from '../components/ProjectsHeader';
import Features from '../components/Features/Features';
import ProjectsCards from '../components/ProjectsCards/ProjectsCards';
import LastSection from '../components/OurPartners/OurPartners';

export const Home = ({ projects }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <HeroSection />
        <ProjectsHeader />
        <ProjectsCards projects={projects} />
        <Features />
      </div>
    </div>
  )
}

export default Home;