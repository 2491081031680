import React from 'react';
import styles from './herosection.module.css';
import arrowRight from '../../images/arrow-right.svg'
import blob1 from '../../images/blob1.png'
import blob2 from '../../images/blob2.png'

const Home = () => {
  return (
    <section className={styles.wrapper} style={{paddingTop: "90px"}}>
      <img src={blob1} className={styles.blob1} alt="" />
      <img src={blob2} className={styles.blob2} alt="" />
      <div className={styles.container}>
        <h2>
        Unlock the future of investment  with the leading AI-driven launchpad  
        </h2>
        {/* <h2>for <span className={styles.blockchain}>groundbreaking</span>  blockchain projects.</h2> */}
        <p>{`Discover and invest in handpicked, vetted blockchain initiatives with confidence. At ${process.env.REACT_APP_NAME}, we merge advanced AI analysis with expert human insights, ensuring our community gets access to the most promising and secure opportunities in the crypto realm. Join us and be at the forefront of the next big crypto evolution`}</p>
        <div className={styles.highlights}>
          <div className={styles.titles}>
            <h5>+0</h5>
            <h6>IDO Projects</h6>
          </div>
          <div className={styles.titles}>
            <h5>$0</h5>
            <h6>Fund Raised</h6>
          </div>
          <div className={styles.titles}>
            <h5>+0%</h5>
            <h6>Our Capital</h6>
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.allProjects}>
            Explore All Projects
            <div>
              <img src={arrowRight} alt="" />
            </div>
          </button>
          <button className={styles.newProject}>DOCS</button>
        </div>
      </div>
    </section>
  );
}
export default Home;
