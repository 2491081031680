import React from "react";
import Badge from "../Badge";
import styles from "./style.module.css";

const ProjectDetails = ({ ido }) => {
  return (
    <div className={styles.card}>
      <h2 className={styles.heading}>Project Details</h2>

      <div className={styles.listItems}>
        {/* first item */}
        <div className={styles.listItem}>
          <div className={styles.iconOuter}>
            <img className={styles.icon} src="/usdt.svg" alt="" />
          </div>
          <div className={styles.justifyBetween}>
            <p className={styles.textSecondary}>Purchase Currency: </p>
            <p className={styles.textPrimary}>{ido.purchase_currency}</p>
          </div>
        </div>
        {/* second item */}

        {/* third item */}

        {/* fourth item */}
        <div className={styles.listItem}>
          <div className={styles.iconOuter}>
            <img className={styles.icon} src="/note.svg" alt="" />
          </div>
          <div className={styles.justifyBetween}>
            <p className={styles.textSecondary}>Whitelisted: </p>
            <Badge
              bgColor={"#EE575F"}
              icon={"/close-circle.svg"}
              text={"FALSE"}
            />
          </div>
        </div>
        {/* fifth item */}
        <div className={styles.listItem}>
          <div className={styles.iconOuter}>
            <img className={styles.icon} src="/coin.svg" alt="" />
          </div>
          <div className={styles.justifyBetween}>
            <p className={styles.textSecondary}>My Allocation: </p>
            <p className={styles.textPrimary}>0 / 5000 {ido.purchase_currency}</p>
          </div>
        </div>

        {/* sixth item */}

        <div className={styles.lastListItem}>
          {/* left */}
          <div className={styles.itemSection}>
            <img src="/coin.png" alt="" />
            <p>{ido.token_symbol}</p>
            <p className={styles.secondary}>0.02</p>
          </div>

          <img src="/arrow-2.svg" alt="" />

          {/* right */}
          <div className={styles.itemSection}>
            <p className={styles.secondary}>1</p>
            <p className="primary">{ido.purchase_currency}</p>
            <img src="/USDT.png" alt="" />
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.content}>
          <p>Minimum Buy:</p>
          <p>10.0 USDT</p>
        </div>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <p>Maximum Buy:</p>
          <p>4000.0 USDT</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
