import React from "react";
import ProjectInfo from "../components/ProjectInfo";
import LearnMore from "../components/LearnMore";
import ProjectDetails from "../components/ProjectDetails";
import StatusCard from "../components/StatusCard";
import TokenMatrics from "../components/TokenMatrics";
import { useParams } from "react-router-dom";

const NewPage = ({ projects }) => {

  let { ido } = useParams();
  ido = projects.find((project) => project.contract_address === ido);

  return (
    <div className="page2" style={{ marginTop: "110px" }}>
      <ProjectInfo ido={ido} />
      <div className="left-section">
        <LearnMore ido={ido} />
        <ProjectDetails ido={ido} />
        <StatusCard ido={ido} />
        <TokenMatrics />
      </div>
    </div>
  );
};

export default NewPage;
