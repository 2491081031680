import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import './fonts/MonumentExtended/MonumentExtended-300.otf'
import './fonts/MonumentExtended/MonumentExtended-400.otf'
import './fonts/MonumentExtended/MonumentExtended-500.otf'
// import './fonts/MonumentExtended/MonumenExtended-800.otf'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* scroll to top when route will change */}
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
