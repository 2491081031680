import React from "react";
import styles from "./style.module.css";
import { useAccount } from "wagmi";
import { useContractWrite } from "wagmi";
import { useContractRead } from "wagmi";
import { abi } from "../../constants/constants.js";
import { erc20ABI } from "wagmi";
const StatusCard = ({ ido }) => {
  const { address } = useAccount();
  const [amount, setAmount] = React.useState(10);

  const { data, isLoading, isSuccess, write } = useContractWrite({
    address: ido.contract_address,
    abi: abi,
    functionName: "purchase",
    args: [amount * 10 ** 18],
  });

  const { write: Approve } = useContractWrite({
    address: ido.purchase_currency_contract_address,
    abi: erc20ABI,
    functionName: "approve",
    args: [ido.contract_address, "30000000000000000000000000"],
  });

  const { data: allowance } = useContractRead({
    address: ido.purchase_currency_contract_address,
    abi: erc20ABI,
    functionName: "allowance",
    // user address against usdt contract
    args: [address, ido.contract_address],
  });

  console.log(allowance);
  function buyButton() {
    if (!address) {
      return {
        text: "Connect Wallet",
        func: () => {
          alert("Connect Wallet");
        },
      };
    } else if (Number(allowance) + 1 < amount) {
      return {
        text: "Approve",
        func: () => {
          Approve();
        },
      };
    } else {
      return {
        text: "Purchase",
        func: () => {
          write();
        },
      };
    }
  }

  buyButton = buyButton();
  const handleKeyDown = (event) => {
    if (
      !(
        /[0-9]/.test(event.key) ||
        event.key === "Backspace" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        (event.key === "." && event.target.value.indexOf(".") === -1)
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.statusCard}>
      <div className={styles.statusIndicator}>
        <p className={styles.statusText}>Status:</p>
        <div className={styles.progressDot}>
          <img className={styles.dot} src="/green-dot.svg" alt="" />
          <p className={styles.progressText}>In Progress</p>
        </div>
      </div>

      <div className={styles.progress}>
        <p className={styles.progressTitle}>Progress (26%)</p>
        <div className={styles.progressBar}></div>

        <div className={styles.spaceBetween}>
          <p className={styles.textSecondary}>136.12 USDT</p>
          <p className={styles.textSecondary}>10000.0 USDT</p>
        </div>
      </div>

      <div className={styles.buyCoin}>
        <h3>Buy Coin</h3>

        <div className={styles.coinSection}>
          <img src="/coin.png" alt="coin" />
          <div className={styles.spaceBetween}>
            <div className="">
              <p className={styles.coinTextSecondary}>Name:</p>
              <p className={styles.coinTextPrimary}>{ido.token_name}</p>
            </div>
            <div>
              <p
                className={[styles.coinTextSecondary, styles.textRight].join(
                  " "
                )}
              >
                Amount:
              </p>
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
                onKeyDown={handleKeyDown}
                className={[styles.coinTextPrimary, styles.input].join(" ")}
              />
            </div>
          </div>
        </div>
      </div>
      {/* buy button */}
      <button onClick={buyButton.func}>{buyButton.text}</button>
    </div>
  );
};

export default StatusCard;
